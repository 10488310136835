<template>
    <div>
      <div class="container-fluid">
        <div class="col-md-12">
          <div class="alt-2"></div>
          <h1 class="big_title text-center">
            Contrato de Comisión Mercantil Solicitante
          </h1>
          <br>
          
          <p style="text-align: center;"><strong>CARÁTULA DE COMISIÓN MERCANTIL</strong></p>
          <div class="parent">
              <div class="div4"><strong>NOMBRE DEL PRODUCTO: CRÉDITO VERDE</strong></div>
              <div class="div5"><strong>TIPO DE PRODUCTO: Comisión Mercantil</strong></div>
              <div class="div1"><strong>IDENTIFICACIÓN DE LA PLATAFORMA</strong></div>
              <div class="div2"><strong>MEDIOS DE DEPÓSITO</strong></div>
              <div class="div3"> <strong>MEDIOS DE DISPOSICIÓN</strong> </div>
              <div class="div6">
                  <ul class="list-disc">
                      <li>
                          Página de internet: <br> <a :href="$sanitizeUrl('https://www.redgirasol.com/')" target="_blank">www.redgirasol.com</a>
                      </li>
                  </ul> 
              </div>
              <div class="div7">
                  <ul class="list-disc">
                      <li>
                          Transferencias Electrónicas
                      </li>
                      <li>
                          Efectivo a través de corresponsales
                      </li>
                      <li>
                          Tarjeta de débito
                      </li>
                      <li>
                          Domiciliación bancaria
                      </li>
                  </ul>
              </div>
              <div class="div8">
                  <ul class="list-disc">
                      <li>
                          Transferencias Electrónicas
                      </li>
                  </ul>
              </div>
              <div class="div9"><strong>COMISIONES RELEVANTES</strong></div>
              <div class="div10"><ul class="list-disc"><li>Gastos de pre-originación: 2% del valor de la Factura</li></ul></div>
              <div class="div11">Para más información y otras comisiones consulte la cláusula 5</div>
              <div class="div12">
                  <strong>ADVERTENCIAS</strong><br>
                  "Los recursos de los Usuarios en las operaciones realizadas con Red Girasol, S.A.P.I. de C.V., Institución de Financiamiento Colectivo no se encuentran garantizados por ninguna autoridad" <br><br>
                  "Los rendimientos de esta inversión son estimados y no están asegurados"<br>
                  “Incumplir tus obligaciones te pueden generar comisiones e intereses moratorios”
              </div>
              <div class="div13"><strong>SEGUROS Y SERVICIOS ADICIONALES</strong> </div>
              <div class="div14">
                  Seguro: No aplica <br>
                  Servicio Adicional: No aplica
              </div>
              <div class="div15"> 
                  Aseguradora: No aplica <br>
                  Prestado por: No aplica
              </div>
              <div class="div16">
                  Cláusula: No aplica <br>
                  Cláusula: No aplica
              </div>
              <div class="div17">
                  <strong>ESTADO DE OPERACIÓN</strong> Periodicidad: Mensual <br>
                  Disponible: Página de internet <a :href="$sanitizeUrl('https://www.redgirasol.com/')" target="_blank">www.redgirasol.com</a>
              </div>
              <div class="div18"> 
                  <strong>Aclaraciones y reclamaciones </strong> <br>
                  Unidad Especializada de Atención a Usuarios: Jorge Armando Cárdenas Tijerina <br>
                  Correo electrónico: une@redgirasol.com <br>
                  Página de Internet: <a :href="$sanitizeUrl('https://www.redgirasol.com/aclaraciones')" target="_blank">www.redgirasol.com/aclaraciones</a>
              </div>
              <div class="div19"> 
                  <strong>Registro de Contratos de Adhesión Núm: 15895-452-036334/01-02390-0622</strong> <br>
                  Comisión Nacional para la Protección y Defensa de los Usuarios de Servicios Financieros (CONDUSEF)
                  Teléfono: 01 800 999 8080 y 53400999. Página de Internet. www.gob.mx/condusef 
              </div>
          </div>
  
          <div v-if="!onlyCoverPage" class="text-justify">
              <strong>CONTRATO DE COMISIÓN MERCANTIL</strong> (en lo sucesivo “<u>Contrato</u>”) que celebran por una parte {{userName}}{{personTypeText}}{{userLegalRepresentativeName}}, (en lo sucesivo "<u>Comitente</u>"), y por la otra Red Girasol, S.A.P.I. de C.V., Institución de Financiamiento Colectivo, representada en este acto por Jorge Armando Cárdenas Tijerina (en lo sucesivo "<u>Comisionista</u>" y en conjunto con el Comitente como las “<u>Partes</u>” o individualmente cada uno como una “<u>Parte</u>”); mismo que sujetan al tenor de las siguientes Declaraciones y Cláusulas.
              <br>
              <br>
              <div style="text-align: center"><strong>DECLARACIONES</strong></div>
              <br>
  
              <ol class="text-justify" style="font-weight:bold">
              <li style="font-size:16px!important"><span style="font-weight:normal">Declara el Comisionista a través de su representante legal que: </span><br> <br>
                  <ol class="text-justify" style="font-weight:bold">
                  <li style="font-size:16px!important"><span style="font-weight:normal">Es una sociedad constituida de conformidad con las leyes de los Estados Unidos Mexicanos (“<u>México</u>”) según consta en la escritura pública número 49,903 de fecha 14 de abril de 2016 otorgada ante la fe del licenciado Eduardo Adolfo Manautou Ayala, titular de la Notaría Pública No. 123 con ejercicio en Monterrey, Nuevo León y cuyo primer testimonio quedó inscrito en el registro público de la ciudad de Monterrey, Nuevo León bajo el folio mercantil electrónico número 160394*1. Asimismo, declara que realiza operaciones de financiamiento colectivo en términos de la Ley FinTech y que cuenta con los recursos y medios necesarios para mantener dicha autorización.</span></li><br>
                  <li style="font-size:16px!important"><span style="font-weight:normal">Su representante cuenta con las facultades necesarias y suficientes para suscribir el presente Contrato, mismas que se encuentran vigentes y no le han sido revocadas o limitadas en forma alguna a la fecha de celebración del presente contrato.</span></li><br>
                  <li style="font-size:16px!important"><span style="font-weight:normal">Su objeto social consiste en, entre otros, recibir y publicar las solicitudes de operaciones de financiamiento colectivo de deuda de conformidad con el artículo 16 fracción 1 de la Ley FinTech (“<u>Operaciones</u>”) de los Solicitantes y sus Proyectos a través del Sitio o medio de comunicación electrónica o digital que utilice para realizar sus actividades.</span></li><br>
                  <li style="font-size:16px!important"><span style="font-weight:normal">Que: (i) se abstendrá de privilegiar los intereses de algún Cliente sobre otro o actuar con conflictos de interés en la ejecución de estos; (ii) mantendrá informados a los Clientes de los actos realizados en la ejecución de los mandatos o comisiones de que se trate; y (iii) dará a conocer de forma clara las contraprestaciones que, en su caso, se cobrarían por la ejecución del mandato. Para los efectos de esta declaración, se entenderá por Cliente tanto a los Inversionistas como a los Solicitantes en los términos del artículo 16 de la Ley FinTech. </span></li><br>
                  <li style="font-size:16px!important"><span style="font-weight:normal">Que para organizarse y operar como institución de financiamiento colectivo (“<u>IFC</u>”) obtuvo una autorización otorgada por la CNBV, previo acuerdo del Comité Interinstitucional, en términos del Capítulo I del Título III de la Ley FinTech. Las IFC, además de cumplir con las obligaciones establecidas en la Ley FinTech y en las disposiciones que de ella emanen, deberán tomar medidas para evitar que se difunda información falsa o engañosa a través de ellas. Adicionalmente, las ITF deberán difundir la información que permita a sus Clientes identificar los riesgos de las Operaciones que celebren con o a través de ellas, conforme a lo previsto en la Ley FinTech. <strong>Ni el Gobierno Federal ni las entidades de la administración pública paraestatal podrán responsabilizarse o garantizar los recursos del Comitente que sean utilizados en las Operaciones que celebren con las ITF o frente a otros, así como tampoco asumir alguna responsabilidad por las obligaciones contraídas por las ITF o por el Comitente frente a otro, en virtud de las Operaciones que celebren</strong>. Las ITF deberán señalar expresamente lo mencionado en este párrafo en sus respectivas páginas de internet, en los mensajes que muestren a través de las aplicaciones informáticas o transmitan por medios de comunicación electrónica o digital que utilicen para el ofrecimiento y realización de sus Operaciones, así como en la publicidad y los contratos que celebren con sus Clientes.</span></li><br>
                  <!-- <li style="font-size:16px!important"><span style="font-weight:normal">Que ha obtenido una constancia electrónica del Comitente a través del Sitio (misma que forma parte integrante de este Contrato), en la que éste manifiesta conocer los riesgos de su inversión previo a la celebración del presente Contrato, de conformidad con lo establecido en el artículo 43 de la CUITF.  </span></li><br> -->
                  <!-- <li style="font-size:16px!important"><span style="font-weight:normal">Que no asegura ningún retorno o rendimiento sobre la inversión realizada por el Comitente ni garantiza el resultado o éxito de las inversiones. </span></li><br> -->
                  <li style="font-size:16px!important"><span style="font-weight:normal">Que ha verificado los datos y autenticidad de los documentos obtenidos de manera digital por parte del Comitente. </span></li><br>
                  <li style="font-size:16px!important"><span style="font-weight:normal">Que su página de internet se identifica bajo el nombre de dominio: <a :href="$sanitizeUrl('https://www.redgirasol.com/')" target="_blank">www.redgirasol.com</a> (el “<u>Sitio</u>”). </span></li><br>
                  </ol>
              </li>
              <li style="font-size:16px!important"><span style="font-weight:normal">Declara el Comitente, por su propio derecho o por conducto de su representante que: </span><br> <br>
                  <ol class="text-justify" style="font-weight:bold">
                  <li style="font-size:16px!important"><span style="font-weight:normal">Es una persona física mayor de edad o una sociedad debidamente constituida conforme a las leyes de México. </span></li><br>
                  <li style="font-size:16px!important"><span style="font-weight:normal">Que su persona se encuentra en pleno uso y goce de sus facultades mentales, o, en su caso, su representante legal cuenta con las facultades necesarias para celebrar el presente contrato según poder que en este acto se tiene a la vista. </span></li><br>
                  <li style="font-size:16px!important"><span style="font-weight:normal">Que la información y documentación presentada al Comisionista es verídica, sabiendo de las consecuencias penales de presentar información falsa. </span></li><br>
                  <li style="font-size:16px!important"><span style="font-weight:normal">Es un usuario registrado del Sitio y reconoce y declara haber revisado, entendido y aceptado sin reserva alguna los términos y condiciones, avisos legales y cualesquiera otras cláusulas, declaraciones, derechos y/u obligaciones que contiene dicho Sitio. </span></li><br>
                  <li style="font-size:16px!important"><span style="font-weight:normal">Reconocer que no se realizará pago alguno al Proveedor para la Instalación de los Productos hasta que el Comitente firme los contratos previstos en la Cláusula 3.3 del presente.</span></li><br>
                  <li style="font-size:16px!important"><span style="font-weight:normal">Mantiene, a su nombre una cuenta bancaria que podrá ser revelada por el Comisionista a través del Sitio.  </span></li><br>
                  <li style="font-size:16px!important"><span style="font-weight:normal">Que ha entregado datos y documentos veraces de manera digital al Comisionista.</span></li><br>
                  <li style="font-size:16px!important"><span style="font-weight:normal">Que reconoce que las Operaciones que realizará en el Sitio podrán ser solamente en pesos, moneda nacional.</span></li><br>
                  <li style="font-size:16px!important"><span style="font-weight:normal">Que no ha obtenido ni obtendrá, previo a la firma de los Documentos del Crédito, otro financiamiento para la adquisición de los Productos a través de otra institución de financiamiento colectivo, en términos de la Ley FinTech.</span></li><br>
                  </ol>
              </li>
              <li style="font-size:16px!important"><span style="font-weight:normal">Declaran las PARTES que :</span><br> <br>
                  <ol class="text-justify" style="font-weight:bold">
                  <li style="font-size:16px!important"><span style="font-weight:normal">Entienden y conocen la terminología empleada en el presente Contrato, no obstante la utilización de tecnicismos propios de la materia que aquí se regula y que son empleados en la determinación de los derechos y obligaciones que aquí se pactan.</span></li><br>
                  <li style="font-size:16px!important"><span style="font-weight:normal">Se reconocen mutuamente la personalidad y, en su caso, representación con la cual cada una de las Partes comparece a la celebración del presente Contrato.</span></li><br>
                  <li style="font-size:16px!important"><span style="font-weight:normal">Están de acuerdo en celebrar el presente Contrato, sujetándolo al tenor de las siguientes cláusulas, apéndices, anexos y convenios accesorios que eventualmente se llegaren a celebrar entre las Partes incluyendo aquellos que se celebren con o sin la intervención o comparecencia de terceros; testimonio de los cuales se pondrán a disposición del Comitente una vez sea celebrado el presente Contrato, en su cuenta en el Sitio y mediante correo electrónico enviado a la cuenta registrada por el Comitente en el Sitio.</span></li><br>
                  </ol>
              </li>
              </ol>
  
              En virtud de lo anterior, las Partes, de común acuerdo, se sujetan a las siguientes:<br>
  
              <br>
              <div style="text-align: center"><strong>CLÁUSULAS</strong></div>
              <br>
  
              <ol class="text-justify" style="font-weight:bold">
              <li style="font-size:16px!important"><u>Objeto del Contrato.</u><span style="font-weight:normal">&nbsp;Por virtud del presente Contrato, el Comitente otorga y confiere una comisión mercantil al Comisionista para obtener el Monto especificado por el Comitente para el financiamiento del Proyecto por parte de los Inversionistas de manera no exclusiva dentro del Territorio, e incluyendo sin limitar la actividades listadas en la Cláusula 3 del presente bajo la Plataforma del Comisionista.<br><br>
                  Por este mismo medio, El Comisionista acepta expresamente la comisión mercantil objeto del presente Contrato, obligándose con el Comitente a desempeñarla de conformidad con las disposiciones de este Contrato, sin exceder de las facultades conferidas en el mismo y sus accesorios. </span> <br> <br>
  
                  <ol class="text-justify" style="font-weight:bold">
                      <li style="font-size:16px!important">Derechos y obligaciones del Comisionista bajo el presente Contrato.<span style="font-weight:normal">&nbsp; A continuación, el Comisionista encontrará un condensado de los derechos y obligaciones a su cargo bajo el presente Contrato, en la inteligencia que dichos derechos y obligaciones se encontrarán sujetos a los términos y condiciones previstos en los numerales correspondientes:</span><br><br>
                          <ol class="text-justify" style="font-weight:bold">
                              <li style="font-size:16px!important">Obligación de obtener el Monto necesario para el Proyecto, en beneficio del Comitente<span style="font-weight:normal">,&nbsp;en cumplimiento de lo establecido en las Cláusulas 1, 2, 3.1 y 3.2. de este Contrato.</span></li>
                              <li style="font-size:16px!important">Obligación de celebrar el Crédito<span style="font-weight:normal">,&nbsp;mediante la celebración de los actos necesarios incluyendo los Documentos del Crédito conforme a lo previsto en las Cláusulas 3.3 y 3.4 (incluyendo, sin limitación, las Cláusulas 3.4.2, 3.4.3 y 3.4.4) del presente.</span></li>
                              <li style="font-size:16px!important">Obligación de poner a disposición del Comitente el Comprobante de Operación, el Estado de Cuenta y el Estado de Operación<span style="font-weight:normal">,&nbsp; y avisar de sus movimientos inusuales y modificaciones, conforme a lo establecido en las Cláusulas 3.4.5 y 3.4.7 del presente.</span></li>
                              <li style="font-size:16px!important">Derecho a percibir las comisiones<span style="font-weight:normal">&nbsp;previstas en la Cláusula 5 del presente.</span></li>
                              <li style="font-size:16px!important">Derecho a percibir los beneficios ambientales de los Productos<span style="font-weight:normal">,&nbsp; en términos de la Cláusula 6.</span></li>
                              <li style="font-size:16px!important">Derecho a terminar este Contrato<span style="font-weight:normal">,&nbsp; en seguimiento de lo establecido en la Cláusula 8. </span></li>
                              <li style="font-size:16px!important">Obligación de cumplir con los impuestos a su cargo <span style="font-weight:normal">,&nbsp; según se describe en la Cláusula 12. </span></li>
                              <li style="font-size:16px!important">Obligación de ejecutar el plan de continuidad de negocio en causa de contingencias operativas<span style="font-weight:normal">,&nbsp; cuando se satisfagan las condiciones previstas en la Cláusula 19 del presente. </span></li>
                              <li style="font-size:16px!important">Obligaciones y derechos diversos<span style="font-weight:normal">,&nbsp; en términos de las Cláusulas 13 a 25 de este Contrato. </span></li>
                          </ol>
                      </li><br>
                      <li style="font-size:16px!important">Derechos y obligaciones del Comitente bajo el presente Contrato.<span style="font-weight:normal">&nbsp; A continuación, el Comitente encontrará un condensado de los derechos y obligaciones a su cargo bajo el presente Contrato, en la inteligencia que dichos derechos y obligaciones se encontrarán sujetos a los términos y condiciones previstos en los numerales correspondientes:</span><br><br>
                          <ol class="text-justify" style="font-weight:bold">
                              <li style="font-size:16px!important">Derecho a recibir el Monto solicitado para el Proyecto<span style="font-weight:normal">&nbsp;en seguimiento de lo establecido en las Cláusulas 2 y 3.4 de este Contrato, y en todo caso, mediante la celebración de los Documentos del Crédito en términos adicionales de lo previsto en la Cláusula 3.3 del presente.</span></li>
                              <li style="font-size:16px!important">Obligación de pagar las comisiones aplicables<span style="font-weight:normal">&nbsp;en beneficio del Comisionista, de conformidad con la Cláusula 5 del presente.</span></li>
                              <li style="font-size:16px!important">Obligación de ceder los beneficios ambientales de los Productos<span style="font-weight:normal">&nbsp;en favor del Comisionista, en términos de la Cláusula 6.</span></li>
                              <li style="font-size:16px!important">Derecho a terminar este Contrato<span style="font-weight:normal">,&nbsp; en seguimiento a lo establecido en la Cláusula 8.</span></li>
                              <li style="font-size:16px!important">Obligación de no competir con Red Girasol<span style="font-weight:normal">,&nbsp;bajo los términos de la Cláusula 11.</span></li>
                              <li style="font-size:16px!important">Obligación de cumplir con los impuestos a su cargo<span style="font-weight:normal">,&nbsp;según se describe en la Cláusula 12.</span></li>
                              <li style="font-size:16px!important">Derecho de acudir ante la UNE y la CONDUSEF<span style="font-weight:normal">,&nbsp;para efectos de la prestación de servicios financieros por parte de Red Girasol, en apego a lo establecido en la Cláusula 13 del presente Contrato.</span></li>
                          </ol>
                      </li><br>
                  </ol>
              
              </li>
  
              <li style="font-size:16px!important"><u>Desempeño de la comisión.</u><span style="font-weight:normal">&nbsp; El Comisionista, para el desempeño de sus obligaciones, deberá obrar con buena fe y realizará sus mejores esfuerzos para el cumplimiento adecuado de la comisión aquí encomendada. El Comisionista deberá hacer su mejor esfuerzo por conocer en todo momento las mejores prácticas de promoción de los Proyectos en el Sitio. El Comitente acepta y reconoce que el Comisionista no realizará ninguna práctica de promoción hasta en tanto el Comitente no realice el Pago Inicial en favor del Comisionista.<br><br>
                  El Comitente reconoce que el hecho de solicitar los Servicios para el financiamiento del Proyecto para la adquisición de los Productos por medio del Sitio no significa que los Productos serán instalados inmediatamente, ya que dicha instalación se encuentra sujeta a una condición suspensiva consistente en: (i) el financiamiento del Proyecto por los Inversionistas a través del Sitio, cubriendo la totalidad del Monto solicitado y, en caso de obtenerlo, (ii) el Comitente firme los Documentos del Crédito.
                  </span><br><br>
              </li>
  
              <li style="font-size:16px!important"><u>Actividades que comprende la comisión.</u><span style="font-weight:normal">&nbsp;Durante la vigencia de este Contrato, el Comisionista estará facultado para desarrollar las siguientes actividades con el fin de promover los Productos y/o Proyectos con los Inversionistas y/o el Comitente:</span> <br> <br>
                  <ol class="text-justify" style="font-weight:bold">
                  <li style="font-size:16px!important">Promoción.<span style="font-weight:normal">&nbsp;El Comisionista estará facultado para solicitar, de la manera más amplia y a su discreción, a los Inversionistas el financiamiento para el financiamiento del Proyecto de una manera comercialmente razonable, pudiendo transmitir material promocional e información diversa a los Inversionistas según sea necesario para cumplir el objeto de este Contrato. La publicidad y promoción podrá ser efectuada mediante todos aquellos medios que el Comisionista considere adecuados para el correcto desarrollo de las actividades de promoción.</span></li><br>
                  <li style="font-size:16px!important">Entrega de Información.<span style="font-weight:normal">&nbsp;El Comisionista estará facultado para entregar toda información a los Inversionistas relacionada al Proyecto del Comitente.</span></li><br>
                  <li style="font-size:16px!important">Medio de venta.<span style="font-weight:normal">&nbsp;El Comisionista a través de su Sitio celebrará los siguientes actos jurídicos para la venta de los Productos, sin limitar a: (i) este contrato de comisión mercantil y (ii) los Documentos del Crédito un contrato de comisión mercantil del Comisionista con el Proveedor, (iii) un contrato de comisión mercantil del Comisionista con los Inversionistas.</span></li><br>
                  <li style="font-size:16px!important">Crédito.<span style="font-weight:normal">&nbsp;El Comisionista deberá llevar a cabo las siguientes actividades relacionadas a los Servicios:</span><br><br>
                      <ol class="text-justify" style="font-weight:bold">
                      <li style="font-size:16px!important">Búsqueda de Inversionistas.<span style="font-weight:normal">&nbsp;El Comisionista deberá buscar e identificar Inversionistas para financiar el Proyecto del Comitente, durante el plazo de [*]. Posterior a la conclusión de dicho plazo de búsqueda e identificación de Inversionistas para el financiamiento del Proyecto del Comitente, el Comisionista pondrá a disposición del Comitente las cantidades depositadas por los Inversionistas para el Proyecto, dentro del plazo de [*] días posteriores a la firma de los Documentos del Crédito.</span></li><br>
                      <li style="font-size:16px!important">Análisis financiero.<span style="font-weight:normal">&nbsp; El Comisionista estará facultado para realizarle al Comitente un análisis de crédito y riesgo respecto de las solicitudes de crédito presentadas por el Comitente. Para ello, el Comisionista solicitará del Comitente (a) un historial crediticio expedido por una sociedad de información crediticia mexicana y (b) comprobantes de ingresos del Comitente para evaluar la capacidad de pago. El Comitente verificará la veracidad de la documentación a través del historial crediticio y en virtud de la declaración 2.9 realizada por el Comisionista. Por otro lado, la información será analizada de conformidad con lo establecido en los Términos y Condiciones del Sitio. Dicha información será publicada por el Comisionista en su Sitio para referencia de los Inversionistas de conformidad con los Términos y Condiciones y el Aviso de Privacidad publicado en el Sitio.</span></li><br>
                      <li style="font-size:16px!important">Registro de garantía mobiliaria.<span style="font-weight:normal">&nbsp;El Comisionista podrá dar de alta la garantía mobiliaria prevista en los Documentos del Crédito en el portal que la Secretaría de Economía establece para el registro de garantías mobiliarias (RUG).</span></li><br>
                      <li style="font-size:16px!important">Actividades frente al Proveedor.<span style="font-weight:normal">&nbsp;(i) celebrar un instrumento jurídico mediante el cual el Comisionista obligue jurídicamente al Proveedor a realizar la instalación de los Productos una vez se encuentre realizado el fondeo del Proyecto; y (ii) a realizar el pago de los recursos efectivamente fondeados, incluyendo  el pago de la Factura expedida por el Proveedor en nombre y representación del Comitente en favor del Proveedor una vez que: (a) el Comitente haya firmado los Documentos del Crédito y (b) el Comitente instruya dicho efecto al Comisionista.</span></li><br>
                      <li style="font-size:16px!important">Depósito.<span style="font-weight:normal">&nbsp;El Comisionista solo podrá recibir recursos del Comitente que provengan directamente de cuentas de depósito de dinero abiertas en una entidad financiera autorizada para recibir dichos depósitos conforme a la normatividad que le resulte aplicable.</span></li><br>
                      <li style="font-size:16px!important">Comprobante de Operación.<span style="font-weight:normal">&nbsp;El Comisionista deberá poner a disposición del Comitente comprobantes de operación a través de los Medios Oficiales con las siguientes características: (i) denominación social y logotipo de Red Girasol; (ii) medio de disposición, (iii) el folio de la operación, (iv) monto, fecha y hora, (v) tipo de operación, (vi) datos de identificación de la cuenta y (vii) concepto y monto de las comisiones cobradas.</span></li><br>
                      <li style="font-size:16px!important">
                          Disposiciones CONDUSEF.
                          <span style="font-weight:normal">
                              &nbsp;De conformidad con el artículo 16 de las Disposiciones de carácter general de la CONDUSEF en materia de transparencia y sanas prácticas aplicables a las instituciones de tecnología financiera, las Partes acuerdan la siguiente información:
                          </span><br><br>
                          <ol class="text-justify" style="font-weight:bold;list-style-type: lower-roman !important;">
                              <li style="font-size:16px!important"><span style="font-weight:normal">El nombre del obligado solidario: [∙].</span></li>
                              <li style="font-size:16px!important"><span style="font-weight:normal">La fecha de corte para el cálculo de intereses y la forma en que se entregará el crédito podrá ser revisado por el Comitente en los Documentos del Crédito.</span></li>
                              <li style="font-size:16px!important"><span style="font-weight:normal">Tasa de interés fija: [∙].</span></li>
                              <li style="font-size:16px!important"><span style="font-weight:normal">La metodología de cálculo de los intereses ordinarios y moratorios podrá ser revisado por el Comitente en los Documentos del Crédito.</span></li>
                              <li style="font-size:16px!important"><span style="font-weight:normal">La periodicidad y fecha límite de pago o el documento ser revisado por el Comitente en los Documentos del Crédito.</span></li>
                              <li style="font-size:16px!important"><span style="font-weight:normal">Los términos y condiciones para pagos anticipados podrá ser revisado por el Comitente en los Documentos del Crédito.y adelantados;</span></li>
                              <li style="font-size:16px!important"><span style="font-weight:normal">Los medios de pago permitidos y las fechas de acreditación de los pagos podra ser revisado por el Comitente en los Documentos del Crédito;</span></li>
                          </ol><br>
                          <span style="font-weight:normal">Para efectos de claridad, toda la información podrá ser revisada en los Documentos del Crédito, en los estados de cuenta y en el Sitio.</span>
                      </li><br>
                      <li style="font-size:16px!important">Estados de cuenta.<span style="font-weight:normal">&nbsp;El Comisionista deberá poner a disposición del Comitente un estado de cuenta a través del Sitio con las siguientes características: (i) denominación social, domicilio, número de teléfono y logotipo y Sitio de Red Girasol; (ii) nombre del usuario, número de cuenta, (iii) periodo que corresponda, (iv) saldo inicial y final de cada proyecto invertido, (v) movimientos de cargo o abono (incluyendo descripción), (vi) un desglosa de las comisiones cobradas y sus detalles, (viii) los cargos objetados, (ix) el aviso de la UNE y que, en caso de no tener respuesta de la UNE, la notificación de que puede acudir ante la CONDUSEF, (x) impuestos retenidos y (xi) leyendas legales. Además, deberá contener lo siguiente (i) : el Monto del crédito, el saldo inicial y el de la fecha de corte o del final del periodo; (ii) el monto a pagar en el periodo, en su caso, desglosado en capital, intereses y cualquier otro cargo, así como el saldo insoluto de principal;(iii) tratándose de pagos anticipados parciales, la correspondiente reducción del monto de los pagos o del número de pagos pendientes, según sea el caso; (iv) el monto base sobre el cual fue calculado el interés ordinario y moratorio en su caso, en términos de lo previsto en el Contrato; (v) en su caso, la fecha límite de pago, señalando que cuando dicha fecha corresponda a un día inhábil bancario, el pago podrá realizarse sin cargo adicional el siguiente día hábil bancario; (vi) la fecha de corte, y (vii) un recuadro, que indique lo siguiente: (a) CAT calculado de conformidad a las disposiciones emitidas por Banco de México; (b) tasa de interés ordinaria y moratoria, expresada en términos anuales simples y en porcentajes, e (c) intereses cargados en el periodo correspondiente. <br><br>
                          Para efectos de claridad, en caso de actualizarse alguno de los datos que contenga el estado de cuenta del Comitente, o bien, se realicen movimientos inusuales en su respectiva cuenta en el Sitio, el Comisionista deberá comunicar la existencia de dichas circunstancias al Comitente mediante los Medios Oficiales.
                      </span></li><br>
              
                      </ol>
                  </li>
                  </ol>
              </li>
  
              <li style="font-size:16px!important"><u>Actividades excluidas de la comisión.</u><span style="font-weight:normal">&nbsp;Se encuentra expresamente excluido dentro de las actividades comprendidas en la comisión aquí conferida la elección por parte del Comisionista de empezar con el financiamiento del Proyecto para la adquisición de los Productos por parte del Comitente, siendo que dicha elección la realiza el Comitente al firmar este Contrato y realizar el Pago Inicial, además de otras excepciones previstas en este Contrato.</span></li><br>
  
              <li style="font-size:16px!important"><u>Comisión.</u><span style="font-weight:normal">&nbsp;Las Partes acuerdan que el Comisionista percibirá, si el Comitente decide abrir la cuenta en el Sitio para que se financie un Proyecto para la adquisición y financiamiento de los Productos por el Comitente, corresponderá al Comisionista el 2% (dos por ciento) del valor de la Factura más el correspondiente Impuesto al Valor Agregado como comisión de pre-originación. Esta comisión será cobrada al Comitente por transferencia electrónica al momento de abrir la cuenta en el Sitio y a la firma del presente Contrato. En caso de que el Comitente opte (i) por detener el fondeo de su Proyecto colocado en el Sitio o (ii) por no proceder con la adquisición de los Productos una vez que el Proyecto hubiese sido exitosamente fondeado o (iii) por no proceder a la firma de los Documentos del Crédito, esta comisión no será reembolsada y será considerada como una pena convencional.</span> </li> <br>
              
              <li style="font-size:16px!important"><u>Cesión de beneficios ambientales.</u><span style="font-weight:normal">&nbsp;Las Partes acuerdan que el Comitente cede gratuitamente al Comisionista los beneficios ambientales que pudiesen generar los Productos durante la vida útil de los mismos.</span> </li> <br>
  
              <li style="font-size:16px!important"><u>Vigencia.</u><span style="font-weight:normal">&nbsp;El presente Contrato empezará a surtir sus efectos a partir de la fecha de su firma. El Contrato estará vigente hasta en tanto el Crédito sea pagado en su totalidad por el Comitente.</span></li> <br>
  
              <li style="font-size:16px!important">
                  <u>Rescisión.</u>
                  <span style="font-weight:normal">
                      &nbsp;Las Partes podrán rescindir el presente Contrato sin previa declaración judicial, por falta de cumplimiento de su contraparte de cualquiera de sus obligaciones derivadas del mismo y en general por los casos de incumplimiento que al afecto señalen las disposiciones legales correspondientes, para lo cual bastará que la Parte afectada notifique por escrito a la otra con 10 (diez) días naturales de anticipación a la fecha en que deba de surtir efectos la misma. La Parte que se encuentra en incumplimiento tendrá 30 (treinta) días naturales para solventar la acción que originó el incumplimiento a partir de la notificación de incumplimiento de la contraparte. Además, las Partes convienen en forma expresa que las Partes podrán rescindir el presente Contrato, sin necesidad de declaración judicial, en caso de que se presente alguna de las siguientes circunstancias:
                  </span><br>
                  <ol class="text-justify" style="font-weight:bold">
                      <li class="dosdigitos" style="font-size:16px!important"><span style="font-weight:normal">Han proporcionado datos falsos al registrarse o al formular el Contrato, o si durante su vigencia y con motivo de las obligaciones derivadas del mismo, proporcionare información falsa, errónea, insuficiente, o si la información solicitada no se proporcionare o se hiciere en forma extemporánea para la evaluación para realizar el análisis financiero establecido en el inciso 3.4.2 de este Contrato.</span></li><br>
                      <li class="dosdigitos" style="font-size:16px!important"><span style="font-weight:normal">Si se declaran en disolución, liquidación, quiebra o suspensión de pagos.</span></li><br>
                      <li class="dosdigitos" style="font-size:16px!important"><span style="font-weight:normal">Si son emplazados a huelga, o existe amenaza de que se inicie el procedimiento correspondiente.</span></li><br>
                      <li class="dosdigitos" style="font-size:16px!important"><span style="font-weight:normal">Si incumplen, cumplen en forma parcial, suspenden, o de cualquier forma prestan las actividades aquí establecidas de manera irregular, deficiente, negligente o irresponsable; o bien por violar o incumplir cualquiera de las obligaciones a su cargo, con motivo o derivadas del presente contrato.</span></li><br>
                      <li class="dosdigitos" style="font-size:16px!important"><span style="font-weight:normal">Si el Comitente no firma los Documentos del Crédito dentro de un plazo de 10 días hábiles a partir de que el Comisionista obtenga el Monto por parte de los Inversionistas.</span></li><br>
                  </ol>
              </li><br>
  
  
              <li style="font-size:16px!important"><u>Efectos de la Terminación.</u><span style="font-weight:normal">&nbsp;Con excepción de lo establecido en las cláusulas 7 y 8, una vez terminado el presente Contrato, los derechos y obligaciones de las Partes terminarán, salvo por aquellas que por su naturaleza deban sobrevivir a la terminación de este Contrato.</span></li> <br>
  
              <li style="font-size:16px!important"><u>Relación entre las Partes.</u><span style="font-weight:normal">&nbsp;Queda expresamente convenido que de ninguna manera el presente contrato deberá o podrá ser considerado o interpretado para crear una coinversión, sociedad, representación, asociación, asociación en participación o relación de agencia entre las Partes para ningún propósito.</span></li> <br>
  
              <li class="dosdigitos" style="font-size:16px!important">
                  <u>No competencia.</u><span style="font-weight:normal">
                  &nbsp;Desde la firma del presente Contrato, durante su vigencia y hasta 3 (tres) años después de la terminación del mismo, el Comitente no podrá, por su propia cuenta, en nombre o por cuenta de, o en relación con alguna Persona, directa o indirectamente, bajo cualquier carácter, incluyendo pero no limitado a, empleador, empleado, funcionario, consejero, gerente, principal, agente, representante, parte en un contrato de coinversión, socio, accionista o cualquier otro titular de participaciones, contratista independiente, licenciante, licenciatario, franquiciador, franquiciado, distribuidor, consultor, asesor, promotor, proveedor, fideicomisario o mediante y a través de cualquier corporación, sociedad cooperativa, sociedad, empresa de responsabilidad limitada, fideicomiso, asociación irregular o de otra manera, continuar con, comprometerse a, tener interés financiero alguno o de otra índole en, o de alguna otra manera estar involucrado comercialmente en cualesquiera sociedades, actividades o negocios en todo o alguna parte del Territorio que sean substancialmente similares o iguales o que estén en competencia con Red Girasol. <br><br>
              </span>
                  <ol class="text-justify" style="font-weight:bold">
                  <li class="dosdigitos" style="font-size:16px!important">
                      <span style="font-weight:normal">
                          Desde la firma del presente Contrato, durante su vigencia y hasta 3 (tres) años después de la terminación del mismo, el Comitente no podrá, por su propia cuenta, a nombre de, o en relación con alguna Persona, directa o indirectamente, bajo cualquier carácter, incluyendo pero no limitado a, como empleador, empleado, funcionario, consejero, gerente, principal, agente, representante, parte en un contrato de coinversión, socio, accionista u otro titular de participaciones, contratista independiente, licenciante, licenciatario, franquiciador, franquiciado, distribuidor, consultor, asesor, promotor, proveedor o fideicomisario o mediante y a través de cualquier corporación, sociedad cooperativa, sociedad, empresa de responsabilidad limitada, fideicomiso, asociación irregular o de otra manera:
                      </span><br><br>
                      <ol class="text-justify" style="font-weight:bold">
                          <li class="dosdigitos" style="font-size:16px!important"><span style="font-weight:normal">Contactar a o captar el negocio de (o procurar o asistir en el contacto o captación del negocio de) cualquier Inversionista o Proveedor para bienes y servicios similares o iguales a aquellos previamente ofrecidos por Red Girasol;</span></li><br>
                          <li class="dosdigitos" style="font-size:16px!important"><span style="font-weight:normal">Aceptar (o procurar o asistir en la aceptación de) cualquier negocio de cualquier Inversionista o Proveedor para bienes y servicios similares o iguales a aquellos ofrecidos en cualquier momento por Red Girasol; o</span></li><br>
                          <li class="dosdigitos" style="font-size:16px!important"><span style="font-weight:normal">Suministrar (o procurar o asistir en el suministro de) a cualquier Inversionista o Proveedor cualesquier bienes y servicios similares o iguales a aquellos previamente ofrecidos por Red Girasol.</span></li><br>
                      </ol>
                  </li>
                  <li class="dosdigitos" style="font-size:16px!important">
                      <span style="font-weight:normal">
                          Desde la firma del presente Contrato, durante su vigencia y hasta 3 (tres) años después de la terminación del mismo, el Comitente no podrá, por su propia cuenta, a nombre de, o en relación con alguna Persona, directa o indirectamente, bajo cualquier carácter, incluyendo pero no limitado a, como empleador, empleado, funcionario, consejero, gerente, principal, agente, representante, parte en un contrato de coinversión, socio, accionista u otro titular de participaciones, contratista independiente, licenciante, licenciatario, franquiciador, franquiciado, distribuidor, consultor, asesor, promotor, proveedor o fideicomisario o mediante y a través de cualquier corporación, sociedad cooperativa, sociedad, empresa de responsabilidad limitada, fideicomiso, asociación irregular o de otra manera:
                          <br>
                          <br>
                          <ol class="text-justify" style="font-weight:bold">
                              <li class="dosdigitos" style="font-size:16px!important"><span style="font-weight:normal">Emplear, ofrecer empleo a, o solicitar el empleo o compromiso de, o de otra manera alentar a cualquier individuo que sea empleado por Red Girasol a terminar su relación laboral con los mismos, sin importar si dicho individuo pudiere incurrir en algún incumplimiento de su contrato o los términos del empleo al terminar el empleo con Red Girasol; o</span></li><br>
                              <li class="dosdigitos" style="font-size:16px!important"><span style="font-weight:normal">Procurar o asistir a cualquier Persona para emplear, ofrecer empleo a o solicitar el empleo o compromiso de, o de otra manera alentar a cualquier dicho individuo a terminar su relación laboral con Red Girasol.</span></li><br>
                          </ol>
                      </span>
                  </li><br>
                  <li class="dosdigitos" style="font-size:16px!important"><span style="font-weight:normal">Desde la firma del presente Contrato, durante su vigencia y hasta 3 (tres) años después de la terminación del mismo, el Comitente no podrá, por su propia cuenta, a nombre de, o en relación con alguna Persona, directa o indirectamente, bajo cualquier carácter, incluyendo pero no limitado a, como empleador, empleado, funcionario, consejero, gerente, principal, agente, representante, parte en un contrato de coinversión, socio, accionista u otro titular de participaciones, contratista independiente, licenciante, licenciatario, franquiciador, franquiciado, distribuidor, consultor, asesor, promotor, proveedor o fideicomisario o mediante y a través de cualquier corporación, sociedad cooperativa, sociedad, empresa de responsabilidad limitada, fideicomiso, asociación irregular o de otra manera, interferir o intentar interferir con Red Girasol, como sociedad y en sus operaciones, o persuadir o intentar persuadir a cualquier Inversionista, Proveedor o empleado de Red Girasol para descontinuar o alterar la relación de dicha Persona con Red Girasol.</span></li><br>
                  </ol>
              </li>
  
              <li class="dosdigitos" style="font-size:16px!important"><u>Impuestos.</u><span style="font-weight:normal">&nbsp;Todos y cada uno de los impuestos, contribuciones, derechos y obligaciones que deban pagarse con motivo de las operaciones derivadas de las obligaciones incluídas en el presente Contrato, correrán por cuenta de quien para tales efectos sea señalado como causante por las leyes fiscales aplicables. </span></li> <br>
  
              <li class="dosdigitos" style="font-size:16px!important"><u>Avisos y notificaciones.</u><span style="font-weight:normal">&nbsp;Todos los avisos, notificaciones, demandas y comunicaciones requeridas conforme a este Contrato deberán ser enviados por correo electrónico y por escrito y se considerará que se notificó debidamente si se entrega personalmente o se envía por correo certificado, correo registrado con el importe pre-pagado. Las Partes señalan como sus domicilios y direcciones convencionales para efectos de oír y recibir notificaciones, los siguientes:</span><br><br>
                  Al Comisionista: <br><br>
                  <span style="font-weight:normal">
                      Red Girasol, S.A.P.I. de C.V., Institución de Financiamiento Colectivo <br>
                      Domicilio: Eugenio Garza Sada 3820 Int. 801, Colonia Mas Palomas, <br>
                      Monterrey, Nuevo León México, C.P. 64780 <br>
                      Tel: (81) 1107 0504<br>
                      Correo: contacto@redgirasol.com<br>
                      Atención: Jorge Armando Cárdenas Tijerina<br><br>
                      </span>
  
                  Al Comitente: <br><br>
                  <span style="font-weight:normal">
                      Nombre: {{userName}}{{ userLegalRepresentativeName }} <br>
                      Tel: {{userPhone}}<br>
                      Correo: {{userEmail}}<br>
                      Fecha: {{currentDate}}<br><br>
  
                      Cualquier cambio o modificación de los domicilios aquí convenidos, deberá ser debidamente notificado por correo electrónico con acuse de recibo a la otra Parte para que surta efectos con por lo menos 10 (diez) días previos a la fecha en que pretenda la Parte notificante hacer cumplir dicho cambio de domicilio. De no cumplirse dicho requisito, la notificación hecha al último domicilio conocido surtirá efectos plenos. <br> <br>
                      Asimismo, en caso de que los Productos deban estar en un lugar específico de acuerdo a los Documentos del Crédito, el Comitente deberá de notificar al Comisionista con todas las formalidades previstas en la presente Cláusula, cuando por cualquier motivo se viere obligado a cambiar los Productos de la ubicación en la que hubieren sido originalmente instalados, para lo cual el Comisionista deberá consentir dicho cambio y el Comitente deberá solicitar el cambio de ubicación de los Productos a los instaladores originales.<br> <br>
                      Cualquiera de las Partes de este Contrato podrá designar un nuevo domicilio u otro domicilio para notificaciones posteriores mediante notificación dada por escrito y observando lo estipulado en los párrafos anteriores.<br> <br>
                      </span>
                      <span style="font-weight:normal">
                          Red Girasol tiene a disposición del Comitente una Unidad Especializada de Atención a Usuarios (UNE) con domicilio de su titular en: Ave. Eugenio Garza Sada 3820 interior 801 Colonia Mas Palomas, Monterrey, Nuevo León C.P. 64875. Teléfono de contacto UNE 8111070504, con horario de lunes a viernes de 9:00 a 17:00 horas (horario de la Ciudad de México). Correo electrónico UNE: <a href="mailto:une@redgirasol.com">une@redgirasol.com</a> El Comitente podrá encontrar en el domicilio de la UNE carteles con los montos, conceptos y periodicidad de las comisiones vigentes. <br><br>
                          Los datos del centro de atención telefónica de la CONDUSEF son los siguientes en la Ciudad de México 53400999 y del interior de la República 018009998080, la página de internet www.condusef.gob.mx, correo electrónico: asesoria@condusef.gob.mx. <br><br>
                          Red Girasol pone a disposición del Comitente su página de internet <a :href="$sanitizeUrl('https://www.redgirasol.com/')" target="_blank">www.redgirasol.com</a>, las cuentas de sus redes sociales, su programa de educación financiera e información gratuita para evitar posibles riesgos tecnológicos, cibernéticos y de fraude, mismos que se podrán enviar al correo electrónico registrado por el Comitente en el Sitio.
                      </span>
              </li>
  
              <li class="dosdigitos" style="font-size:16px!important"><u>Cesión de Derechos.</u><span style="font-weight:normal">&nbsp;El Comitente no podrá ceder ni transferir los derechos y obligaciones adquiridos por virtud del presente Contrato, sin el consentimiento previo y por escrito del Comisionista. En caso contrario, aquella cesión o trasferencia de derechos y/u obligaciones sin el consentimiento de la otra Parte será nula. </span></li> <br>
  
              <li class="dosdigitos" style="font-size:16px!important"><u>Información Confidencial.</u><span style="font-weight:normal">&nbsp;Las Partes convienen en que cualquier información revelada o proporcionada por las Partes (cualquiera de sus filiales, sociedad controladora o subsidiarias), así como toda la información generada con relación al cumplimiento del objeto del presente Contrato, ya sea de manera escrita, verbal, electrónica, magnética o cualquier otra tecnología y demás información confidencial, o de cualquier otra índole, será considerada propiedad de dicha Parte.<br><br>
                  Las Partes no revelarán a terceras Personas ninguna información propiedad de la otra Parte, debiendo utilizarla exclusivamente para el desarrollo del objeto de este instrumento. Una vez terminado este Contrato, las Partes deberán entregar en forma inmediata a la otra Parte toda la información y materiales que le hayan sido proporcionados.  </span> <br> <br>
  
              <li class="dosdigitos" style="font-size:16px!important"><u>Acuerdo único y modificaciones.</u><span style="font-weight:normal">&nbsp;Lo contenido en este Contrato constituye el acuerdo total entre las Partes respecto al objeto de este Contrato. Cualquier modificación a los términos y condiciones del presente Contrato, Red Girasol le notificará mediante a través de los Medios Oficiales, con 30 (treinta) días naturales de anticipación a la fecha que entren en vigor dichos cambios, entendiéndose que el Comitente deberá otorgar su consentimiento expreso conforme a lo previsto en la Cláusula 22 de este Contrato dentro de dicho plazo, de lo contrario, deberá dar su consentimiento, en términos de la Cláusula 22 para dar por terminado el presente Contrato. <br><br>
                  Los avisos de modificaciones al Contrato contendrán, por lo menos: I. Denominación social y logotipo de Red Girasol; II. Domicilio y teléfono de contacto de Red Girasol, así como domicilio, teléfono y correo electrónico de la Unidad Especializada de Atención de Usuarios (“UNE”); III. Resumen de todas las modificaciones realizadas; IV. Fecha a partir de la cual entran en vigor, y V. Su derecho para dar por terminado el Contrato. Usted puede solicitar la terminación del Contrato dentro de los 30 (treinta) días posteriores al aviso sin responsabilidad alguna a su cargo, y bajo las condiciones pactadas originalmente, sin que Red Girasol pueda cobrarle penalización alguna por dicha causa ni cobrar cantidad adicional alguna con excepción de los adeudos que ya se hubiera generado a la fecha en que el Comitente solicite dar por terminado el Contrato. En el evento de que El Comitente no esté de acuerdo con las modificaciones realizadas, podrá solicitar la terminación del contrato dentro de los 30 (treinta) días posteriores al aviso señalado en el párrafo anterior, sin responsabilidad alguna a su cargo, y bajo las condiciones anteriores a la modificación, siempre y cuando el Comitente no tenga dinero en inversiones de Proyectos vigentes. <br><br>
              </span></li> <br>
  
              <li class="dosdigitos" style="font-size:16px!important"><u>Aclaraciones.</u><span style="font-weight:normal">&nbsp;Cuando el Comitente no esté de acuerdo con alguno de los movimientos que aparezcan en el estado de cuenta respectivo o en los medios electrónicos, ópticos o de cualquier otra tecnología que se hubieren pactado, podrá presentar una solicitud de aclaración dentro del plazo de noventa días naturales contados a partir de la fecha de corte o, en su caso, de la realización de la operación o del servicio. <br><br>
                  La solicitud respectiva podrá presentarse en la UNE de Red Girasol, mediante escrito, correo electrónico o cualquier otro medio por el que se pueda comprobar fehacientemente su recepción. En todos los casos, Red Girasol estará obligada a acusar recibo de dicha solicitud. <br><br>
                  Tratándose de cantidades a cargo del Comitente dispuestas mediante cualquier mecanismo determinado al efecto por la Comisión Nacional para la Protección y Defensa de los Usuarios de los Servicios Financieros en disposiciones de carácter general, el Comitente tendrá el derecho de no realizar el pago cuya aclaración solicita, así como el de cualquier otra cantidad relacionada con dicho pago, hasta en tanto se resuelva la aclaración conforme al procedimiento a que se refiere esta Cláusula. <br><br>
                  Una vez recibida la solicitud de aclaración, Red Girasol tendrá un plazo máximo de cuarenta y cinco días para entregar al Comitente el dictamen correspondiente, anexando copia simple del documento o evidencia considerada para la emisión de dicho dictamen, con base en la información que, conforme a las disposiciones aplicables, deba obrar en su poder, así como un informe detallado en el que se respondan todos los hechos contenidos en la solicitud presentada por el Comitente. <br><br>
                  El dictamen e informe antes referidos deberán formularse por escrito y suscribirse por personal de Red Girasol facultado para ello. En el evento de que, conforme al dictamen que emita Red Girasol, resulte procedente el cobro del monto respectivo, el Comitente deberá hacer el pago de la cantidad a su cargo, incluyendo los intereses ordinarios conforme a lo pactado, sin que proceda el cobro de intereses moratorios y otros accesorios generados por la suspensión del pago realizada en términos de esta disposición;<br><br>
                  Dentro del plazo de cuarenta y cinco días naturales contado a partir de la entrega del dictamen a que se refiere el párrafo anterior, Red Girasol estará obligada a poner a disposición del Comitente en la UNE de Red Girasol, el expediente generado con motivo de la solicitud, así como a integrar en éste, bajo su más estricta responsabilidad, toda la documentación e información que, conforme a las disposiciones aplicables, deba obrar en su poder y que se relacione directamente con la solicitud de aclaración que corresponda y sin incluir datos correspondientes a operaciones relacionadas con terceras personas;<br><br>
                  Hasta en tanto la solicitud de aclaración de que se trate no quede resuelta de conformidad con el procedimiento señalado en este artículo, Red Girasol no podrá reportar como vencidas las cantidades sujetas a dicha aclaración a las sociedades de información crediticia.<br><br>
                  Lo antes dispuesto es sin perjuicio del derecho del Comitente de acudir ante la Comisión Nacional para la Protección y Defensa de los Usuarios de Servicios Financieros o ante la autoridad jurisdiccional correspondiente conforme a las disposiciones legales aplicables y en los términos de este Contrato. Sin embargo, el procedimiento previsto en este artículo quedará sin efectos a partir de que el Comitente presente su demanda ante autoridad jurisdiccional o conduzca su reclamación en términos y plazos de la Ley de Protección y Defensa al Usuario de Servicios Financieros.
              </span></li> <br>
  
              <li class="dosdigitos" style="font-size:16px!important"><u>Gastos.</u><span style="font-weight:normal">&nbsp;La contraprestación aquí establecida, excluye todos los gastos y erogaciones relacionadas con las obligaciones del Comisionista conforme al presente Contrato, por lo tanto, el Comisionista podrá cobrar al Comitente cualquier otro gasto o erogación con la condición de que los mismos sean desglosados en su estado de cuenta mensual.</span></li> <br>
  
              <li class="dosdigitos" style="font-size:16px!important"><u>Caso fortuito y fuerza mayor.</u><span style="font-weight:normal">&nbsp;Las Partes no serán responsables por la falta de realización de las actividades establecidas en el presente Contrato, cuando existan causas fuera de su control incluyendo órdenes de cualesquiera autoridades gubernamentales, civiles y/o militares, huelgas, inundaciones, epidemias, guerras, disturbios y demás causas análogas de caso fortuito o fuerza mayor. Lo anterior, en el entendido, además, que el Comisionista cuenta con un plan de continuidad de negocio, para el caso de contingencias operativas, para lo cual, el Comisionista no podrá excusar el incumplimiento de sus actividades bajo el presente Contrato por la actualización de los supuestos de contingencias operativas ahí previstas. En caso de actualizarse cualquiera de los supuestos previstos en esta Cláusula, el Comisionista deberá comunicar la existencia de dichas circunstancias al Comitente mediante los Medios Oficiales.</span></li> <br>
  
              <li class="dosdigitos" style="font-size:16px!important"><u>Ejercicio de Derechos.</u><span style="font-weight:normal">&nbsp;El hecho de que alguna de las Partes no ejerza alguno de sus derechos derivados del presente contrato, no significa, ni deberá ser considerado, como una renuncia o pérdida de tales derechos.</span></li> <br>
  
              <li class="dosdigitos" style="font-size:16px!important"><u>Correlación de documentos.</u><span style="font-weight:normal">&nbsp;Las PARTES no podrán alegar desconocimiento o inaplicabilidad de alguna de las normas que en cualquier documento se establezcan, por lo que cualquier incumplimiento de los mismos será un incumplimiento a los términos y condiciones pactados en este Contrato, así también no podrá alegarse por las Partes el desconocimiento  de la terminología empleada en este Contrato, sus accesorios y demás instrumentos legales relacionados con este Contrato y con la relación jurídica que sostengan las Partes por diversos acuerdos.</span></li> <br>
  
              <li class="dosdigitos" style="font-size:16px!important"><u>Firma.</u><span style="font-weight:normal">&nbsp;Las Partes acuerdan que para manifestar su consentimiento expreso y firmar el presente Contrato podrán: (i) por medio de la Firma Electrónica Avanzada emitida por el Servicio de Administración Tributaria, y de conformidad con los artículos 2, 7, 8, 22 y demás aplicables de la Ley de Firma Electrónica Avanzada; (ii) por medio de la firma electrónica emitida por la Secretaría de Economía; o (iii) mediante clic en el recuadro de ACEPTO junto con el ingreso de la contraseña respectiva. Cualquiera de los métodos previamente anunciados tendrá como efecto el sometimiento expreso a las disposiciones del presente y por lo tanto surtirán efectos plenos para las Partes, frente a ellos mismos y frente a terceros. Así mismo, el Comitente expresamente renuncia a argumentar desconocimiento de la firma del presente.</span></li> <br>
  
              <li class="dosdigitos" style="font-size:16px!important"><u>Definciones.</u><span style="font-weight:normal">&nbsp;Las Partes expresamente acuerdan que los términos definidos establecidos en el Apéndice 1 del presente y usados en el clausulado del presente contrato, tendrán el alcance y significado que en los mismos se establece y no su significado literal. Asimismo, las Partes convienen en utilizar dichos términos en los Anexos del presente contrato que al efecto se celebren. </span></li> <br>
  
              <li class="dosdigitos" style="font-size:16px!important"><u>Encabezados y Títulos de las cláusulas.</u><span style="font-weight:normal">&nbsp;Los encabezados y los títulos de las Cláusulas que aparecen en el presente Contrato, se han puesto con el exclusivo propósito de facilitar su lectura, por tanto, no necesariamente definen ni limitan el contenido de las mismas. Para efectos de interpretación de cada Cláusula deberá atenderse exclusivamente a su contenido y de ninguna manera a su título.</span></li><br>
  
              <li class="dosdigitos" style="font-size:16px!important"><u>Legislación  y jurisdicción aplicable.</u><span style="font-weight:normal">&nbsp;Las Partes se someten expresamente para la interpretación, cumplimiento y ejecución de este Contrato a lo establecido en la Ley FinTech, con la supletoriedad establecida en el artículo 10 de la misma, así como a la jurisdicción de los tribunales competentes de la ciudad de Monterrey, Nuevo León, México, respecto a cualquier acción o procedimiento, interpretación y cumplimiento de todo lo pactado en este Contrato, renunciando expresamente a cualquier jurisdicción que les pudiere corresponder en la actualidad o en el futuro por virtud de sus domicilios actuales o futuros o por cualquier otra razón.</span></li><br><br>
              El presente Contrato se firma de conformidad por las Partes que intervienen en el mismo, sin que mediare entre ellos vicio del consentimiento alguno, en la Ciudad de Monterrey, Nuevo León, el día {{ currentDate }}. </span></li><br> <br>
              </ol>
  
              <p style="text-align: center">
              *-*-*-*-*-* <br>
              <br>
              </p>
  
              <p style="text-align: justify">
              Inscripción en el Registro de Contratos de Adhesión de la Comisión Nacional para la Protección y Defensa de los Usuarios de Servicios Financieros (CONDUSEF) No. 15895-452-036334/01-02390-0622
              Fecha: 13/06/2022
  
              </p>
  
              <br>
              <div style="text-align: center"><strong><u>APÉNDICE 1</u></strong></div>
              <div style="text-align: center"><strong>Definciones</strong></div>
              <br>
  
              Para los efectos de este contrato, los siguientes términos tendrán el significado que a continuación se señalan: <br> <br>
  
              <ol class="text-justify" style="font-weight:normal; font-size:16px!important">
                  <li><strong>&ldquo;Comisi&oacute;n por Apertura&rdquo;:</strong> Tiene el significado descrito en la cl&aacute;usula 5.1 del presente Contrato.</li>
                  <li><strong>&ldquo;Contrato&rdquo;:</strong> Significa el presente Contrato de Comisi&oacute;n, sus ap&eacute;ndices y anexos.</li>
                  <li><strong>&ldquo;Cr&eacute;dito&rdquo;:&nbsp;</strong>Significa el contrato de cr&eacute;dito, a ser celebrado por y entre los Inversionistas, representados por Red Girasol, con el Comitente para la adquisici&oacute;n de los Productos.</li>
                  <li><strong>&ldquo;Documentos del Cr&eacute;dito&rdquo;:</strong> Significa (i) el Cr&eacute;dito, (ii) un contrato de prenda sobre los Productos entre los Inversionistas y el Comitente, (iii) un pagar&eacute; a ser firmado por el Comitente en favor de los Inversionistas; y (iv) un contrato de compraventa de bien inmueble por accesi&oacute;n entre el Comitente y el Proveedor.</li>
                  <li><strong>&quot;Enganche&quot;:</strong> Es la cantidad de $[*] (00/100 Moneda Nacional) que realiza el Comitente como pago adelantado contra la Factura.</li>
                  <li><strong>&quot;Factura&quot;:</strong> Es la cantidad de $[*] que corresponde a los Productos.</li>
                  <li><strong>&quot;Factura Restante&quot;:</strong> Significa la Factura menos el Enganche.</li>
                  <li><strong>&quot;Inversionistas&quot;:&nbsp;</strong>Es la o las personas f&iacute;sicas o morales que en conjunto financiar&aacute;n al Comisionista para la adquisici&oacute;n de los Productos.</li>
                  <li><strong>&ldquo;Ley FinTech&rdquo;:</strong> Significa la Ley para Regular las Instituciones de Tecnolog&iacute;a Financiera.</li>
                  <li><strong>&quot;Monto&quot;:&nbsp;</strong>Es la cantidad que los Inversionistas deber&aacute;n financiar al Comitente equivalente a: (i) la Factura Restante, m&aacute;s la Comisi&oacute;n por Fondeo, m&aacute;s la Comisi&oacute;n por Apertura.</li>
                  <li><strong>&quot;Pago Inicial&quot;:</strong> Significa el Enganche m&aacute;s la Comisi&oacute;n por Apertura a ser pagado por el Comitente en favor del Comisionista.</li>
                  <li><strong>&quot;Parte&quot;:</strong> Lo constituyen el Comisionista o el Comitente en forma indistinta y separada, seg&uacute;n corresponda.</li>
                  <li><strong>&quot;Partes&quot;:</strong> Lo constituyen el Comisionista y el Comitente en forma conjunta.</li>
                  <li><strong>&quot;Persona&quot;:</strong> Significa e incluye personas f&iacute;sicas, corporaciones, sociedades, sociedades en comandita simple, sociedades de responsabilidad limitada, empresas de responsabilidad limitada, sociedades generales, sociedades an&oacute;nimas, coinversiones, asociaciones, empresas, fundaciones de intereses privados, fideicomisos, bancos, fiduciarios, fideicomisos inmobiliarios, fideicomisos comerciales u otras organizaciones, sean personas morales o no, y todas las Autoridades Gubernamentales.</li>
                  <li><strong>&quot;Productos&quot;:</strong> Son los activos propiedad del Proveedor, que el Comitente desea adquirir a trav&eacute;s del Proyecto promovidos por el Comisionista con los Solicitantes.</li>
                  <li><strong>&quot;Proveedor&quot;:&nbsp;</strong>Es la persona f&iacute;sica o moral que es propietario de los Productos que pretende vender al Comitente.</li>
                  <li><strong>&quot;Proyecto&quot;:</strong> Es el proyecto solicitado por el Solicitante para el financiamiento del Monto por Inversionistas para la adquisici&oacute;n de los Productos y el pago de comisiones en favor del Comisionista, formalizado a trav&eacute;s de los Documentos del Cr&eacute;dito.</li>
                  <li><strong>&quot;Red Girasol&quot;:</strong> Es la persona moral Red Girasol, S.A.P.I. de C.V., Instituci&oacute;n de Financiamiento Colectivo</li>
                  <li><strong>&quot;Servicios&quot;:</strong> Ser&aacute;n todo tipo de actividades tendientes a conseguir financiamiento para la adquisici&oacute;n de los Productos del Proveedor, las cuales entre otras incluir&aacute;n, la exhibici&oacute;n de la informaci&oacute;n del Comisionista, la realizaci&oacute;n de todo tipo de actividades mediante las cuales se promueva la inversi&oacute;n para la adquisici&oacute;n de los Productos, incluyendo la entrega de todo tipo de informaci&oacute;n relativa a la transacci&oacute;n. Una vez que el Comitente opte por la adquisici&oacute;n de los Productos, el Comisionista deber&aacute; conseguir el Monto a financiarse con Inversionistas.</li>
                  <li><strong>&quot;Territorio&quot;:</strong> Significa el territorio que comprenden los Estados Unidos Mexicanos.</li>
                  <li><strong>&ldquo;Medios Oficiales&rdquo;:</strong> Significa (i) el Sitio, (ii) el correo electr&oacute;nico <a href="mailto:contacto@redgirasol.com">contacto@redgirasol.com</a>, (iii) el tel&eacute;fono establecido en la cl&aacute;usula d&eacute;cimo segunda anterior y (iv) el estado de cuenta. El Comisionista adem&aacute;s cuenta con las redes sociales oficiales se&ntilde;aladas en el Sitio.</li>
              </ol>
          </div>
  
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import applicantInfoHelper from "@/helpers/applicantInfoHelper";
  
  export default {
  name: "LatestApplicantMerchantContract",
  mixins: [applicantInfoHelper],
  props: {
      fillUserData: {
        type: Boolean
        , default: false
      },
      signedDate: { required: false, default:null },
      onlyCoverPage: { required: false, default:false }
    },
    computed:{
      userName: function () {
          if (this.fillUserData && this.user) {
              return this.user.complete_name;
          } else {
              return "";
          }
        },
        personTypeText: function () {
          if (this.fillUserData && this.user) {
              if (!this.userIsMoral(this.user.user_person_type)) {
                  return " por su propio derecho";
              }
          } else {
              return "";
          }
        },
        userLegalRepresentativeName: function () {
          if (this.fillUserData && this.user) {
              let name = "";
              if (this.userIsMoral(this.user.user_person_type)) {
                  name = " representada en este acto por "+this.user.legal_representative_name;                
              }
              return name;
          } else {
              return "";
          }
        },
        userEmail: function () {
          if (this.fillUserData) {
              return (this.user === null) ? "-" : this.user.email;
          } else {
              return "";
          }
        },
        userPhone: function () {
          if (this.fillUserData) {
              return (this.user === null) ? "-" : this.user.phone;
          } else {
              return "";
          }
        },
        currentDate: function () {
          if (this.signedDate) {
              return this.signedDate;
          } else {
              return this.signatureFormatDate(new Date());
          }
        },
        currentClabe: function () {
          if (this.stpClabe) {
              return this.stpClabe;
          } else {
              return "____________";
          }
        },
    },
  }
  </script>
  
  <style scoped>
  ol {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;
  }
  
      ol > li {
      display: table;
      counter-increment: item;
      margin-bottom: 0.6em;
      } 
  
      ol > li:before {
      content: counters(item, ".") ". ";
      display: table-cell;
      padding-right: 0.6em;    
      }
  
      li ol > li {
      margin: 0;
      }
  
      li ol > li:before {
      content: counters(item, ".") " ";
      }
  
      .parent {
          display: grid;
          grid-template-columns: repeat(6, 1fr);
          grid-template-rows: auto 1fr;
          grid-column-gap: 0px;
          grid-row-gap: 0px;
          margin-top: 1rem;
          margin-bottom: 3rem;
          font-size: 14px;
      }
          .parent > div {
                  border: solid .5px #d2d2d2;
                  padding-left: 2rem;
              }
      .list-disc {        
          list-style-type: disc !important;
          margin-left: 3rem !important;
      }
  
      .div1 { 
          grid-area: 3 / 1 / 4 / 3;
          text-align: center;
      }
      .div2 { 
          grid-area: 3 / 3 / 4 / 5;
          text-align: center; 
      }
      .div3 { 
          grid-area: 3 / 5 / 4 / 7; 
          text-align: center;
      }
      .div4 { grid-area: 1 / 1 / 2 / 7; }
      .div5 { grid-area: 2 / 1 / 3 / 7; }
      .div6 { grid-area: 4 / 1 / 5 / 3; }
      .div7 { grid-area: 4 / 3 / 5 / 5; }
      .div8 { grid-area: 4 / 5 / 5 / 7; }
      .div9 { 
          grid-area: 5 / 1 / 6 / 7;
          text-align: center; 
      }
      .div10 { grid-area: 6 / 1 / 7 / 4; }
      .div11 { grid-area: 6 / 4 / 7 / 7; }
      .div12 { grid-area: 7 / 1 / 9 / 7; }
      .div13 { 
          grid-area: 9 / 1 / 10 / 7; 
          text-align: center;    
      }
      .div14 { grid-area: 10 / 1 / 11 / 3; }
      .div15 { grid-area: 10 / 3 / 11 / 5; }
      .div16 { grid-area: 10 / 5 / 11 / 7; }
      .div17 { grid-area: 11 / 1 / 12 / 7; }
      .div18 { grid-area: 12 / 1 / 13 / 7; }
      .div19 { grid-area: 13 / 1 / 14 / 7; }
  
  </style>