<template>
  <div>
    <vx-card class="mb-5" title="Mis contratos." >
      <div class="vx-row">
        <div class="vx-col w-full">
          <p>
            Consulta los contratos que todos los integrantes de la Red aceptan al entrar a nuestra comunidad.
          </p>
        </div>
      </div>

      <div class="vx-row mt-6" v-if="allContracts.length > 0 && isMounted">
        <div class="vx-col w-full">
          <div class="flex flex-wrap items-center">
            <template v-for="(contract, idx ) in allContracts">
              <vs-button type="border" class="sm:px-base px-4 mr-4 mb-3" :key="idx" @click="downloadContract(contract)">
                {{contract.name}}
              </vs-button>
              <template v-if="contract.type == 4">
                <vs-button type="border" class="sm:px-base px-4 mr-4 mb-3" @click="goToCoverMerchantContract(contract)">Carátula Comisión Mercantil Solicitante</vs-button>
              </template>
            </template>
          </div>
        </div>
      </div>

      <div class="vx-row mt-6" v-if="!isMounted">
        <div class="vx-col w-full">
          <div class="flex flex-wrap items-center">
            <p>Cargando información...</p>
          </div>
        </div>
      </div>
    </vx-card>

    <vs-popup fullscreen :active.sync="popupActive" title="Contrato" >
      <!-- <vs-button class="sm:px-base px-4 mb-4" @click="scrollToElement({behavior: 'smooth'})">Ir al final</vs-button> -->
      <vs-button v-if="downloadButtonEnabled" class="sm:px-base px-4 ml-2 mb-4" @click="downloadPdfContract">Descargar</vs-button>
      <vx-card class="mb-5" title="EL CONTRATO" title-color="primary" >
        <latest-terms-contract v-if="termsActive" />
        <latest-privacy-contract v-if="privacyActive" />
        <latest-applicant-merchant-contract v-if="merchantActive" :fill-user-data="true" :only-cover-page="onlyCoverPage" :signed-date="signatureFormatDate(currentMetaData.acceptance_date)"/>
        <latest-advertising-contract v-if="advertisingActive" />
      </vx-card>
      <div class="sign-area">
        <vx-card class="mb-2" title-color="primary" title="TU FIRMA">
          <h5>Datos de la firma:</h5>
          <p class="my-2"><span class="bold">Nombre del documento: </span> {{currentMetaData.document}}</p>
          <p class="my-2"><span class="bold">Versión del documento: </span> {{currentMetaData.version}}</p>
          <p class="my-2"><span class="bold">Fecha de aceptación: </span> {{signatureFormatDate(currentMetaData.acceptance_date)}}</p>
          <p class="my-2"><span class="bold">Agente: </span> {{currentMetaData.agent}}</p>
          <p class="my-2"><span class="bold">IP: </span> {{currentMetaData.ip}}</p>
          <!--p class="my-2"><span class="bold">Validez del documento: </span> {{signatureValidityDate(currentMetaData.validity_date)}}</p-->
          <p class="my-2"><span class="bold">Correo electrónico: </span> {{currentMetaData.email}}</p>
          <h5 class="mt-5">Cadena de firma electrónica:</h5>
          <p class="text-break text-xs my-2">{{currentSignature}}</p>
        </vx-card>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import LatestTermsContract from "@views/share/contracts/TermsContract/LatestTermsContract.vue";
import LatestPrivacyContract from "@views/share/contracts/PrivacyContract/LatestPrivacyContract.vue";
import LatestApplicantMerchantContract from "@views/applicant/contracts/LatestApplicantMerchantContract.vue";
import LatestAdvertisingContract from "@views/share/contracts/AdvertisingContract/LatestAdvertisingContract.vue";

import dateHelper from "@mixins/dateHelper";
export default {
  name: "ContractsPage",
  data() {
    return {
      fixedContracts: [
        {id: 1, name: 'Términos y condiciones', url: 'terminos-y-condiciones', type: 1 },
        {id: 2, name: 'Aviso de privacidad', url: 'aviso-de-privacidad', type: 2, },
        {id: 3, name: 'Comisión mercantil', url: 'contrato-de-comision-mercantil-cliente', type: 4 },
        {id: 4, name: 'Consentimiento Publicidad', url: '', type: 22 },
      ],

      signedContracts:[],
      hasSignedContracts: false,
      allContracts: [],
      termsActive: false,
      privacyActive: false,
      merchantActive: false,
      advertisingActive: false,
      popupActive: false,
      currentSignature: null,
      isMounted: false,
      currentMetaData: {
        acceptance_date: null,
        agent: null,
        alias: null,
        email: null,
        document: null,
        ip: null,
        password: null,
        validity_date: null,
        version: null,
      },
      downloadButtonEnabled: false,
      onlyCoverPage: false,
      currentContract:null
    }
  },
  mixins: [dateHelper],
  components: {
    LatestTermsContract,
    LatestPrivacyContract,
    LatestApplicantMerchantContract,
    LatestAdvertisingContract
  },
  async mounted(){
    this.isMounted = false
    this.showLoading(true)
    await this.getContractsData();
    this.isMounted = true
    this.showLoading(false)
  },
  methods: {
    async goToCoverMerchantContract(contract){
      this.currentContract = contract;
      if(contract.isSigned){
        if(contract.signature){
          this.resetValues();
          this.currentMetaData = contract.metadata;
          this.onlyCoverPage = true;
          this.downloadButtonEnabled = true;
          this.popupActive = true;
          this.merchantActive = true;
        }
      }
      else {
        const url = `${this.ApiDomain}/${contract.url}`;
        window.open(url, '_blank');
      }
    },
    async downloadContract(contract){
      this.currentContract = contract;
      if(contract.isSigned){
        await this.downloadSignedContract(contract);
      }
      else {
        const url = `${this.ApiDomain}/${contract.url}`;
        window.open(url, '_blank');
      }
    },
    async downloadSignedContract(obj){
      if(obj.signature != null){
        this.currentSignature = obj.signature;
        this.currentMetaData = obj.metadata;
        this.popupActive = true;
        this.resetValues();
        if(obj.type === 1){
          this.termsActive = true;
        }
        else if(obj.type === 2){
          this.privacyActive = true;
        }
        else if(obj.type === 4){
          this.downloadButtonEnabled = true;
          this.merchantActive = true;
        }
        else if(obj.type === 22){
          this.advertisingActive = true;
        }
      }
    },
    resetValues(){
      this.downloadButtonEnabled = false;
      this.onlyCoverPage = false;
      this.termsActive = false;
      this.privacyActive = false;
      this.merchantActive = false;
      this.advertisingActive = false;
    },
    async getContractsData(){
      try {
        const res = await axios.get(`api/v1/applicant/${this.ApplicantId}/getContracts`);
        this.signedContracts = res.data.client;
        this.hasSignedContracts = res.data.client && res.data.client.length > 0;

        const defaultContracts = res.data.default;
        const applicantContracts = res.data.client;
        let allContracts = [];

        defaultContracts.forEach(df => {
          console.log(df.type);
          const iapp = applicantContracts.find(f => f.contract.type === df.type);
          const fiapp = this.fixedContracts.find(f => f.type === df.type);
          if(iapp != null){
            let addToContracts = true;
            if (df.type == 22 && (iapp.signature == null)) {
              addToContracts=false;
            }
            if (addToContracts) {
              allContracts.push({
                name: df.name,
                type: df.type,
                url: fiapp.url,
                isSigned: (iapp.signature != null),
                signature: iapp.signature ?? null,
                metadata: iapp.metadata ?? null,
              })
            }
          }
          else {
            if (df.type != 22) {
              allContracts.push({
                name: df.name,
                type: df.type,
                url: fiapp.url,
                isSigned: false,
                signature: null,
                metadata: null
              })
            }
          }
        })

        this.allContracts = allContracts;
      }
      catch (e) {
      }
    },
    async downloadPdfContract(){
      try {
        let url ="";
        if (this.onlyCoverPage) {
          url = `${this.ApiDomain}/api/v1/applicant/${this.ApplicantId}/downloadPdfContractCover/${this.currentContract.type}?access_token=${this.AccessToken}&2fa_restart_cookie=true`;
        } else {
          url = `${this.ApiDomain}/api/v1/applicant/${this.ApplicantId}/downloadPdfContract/${this.currentContract.type}?access_token=${this.AccessToken}&2fa_restart_cookie=true`;
        }
        window.open(url, "_blank");
      }
      catch (e) {
        console.log(e);
        this.warn(e)
      }
    },
    // scrollToElement(options) {
    //   const el = this.$el.getElementsByClassName('sign-area')[0]; 
    //   if (el) {
    //     el.scrollIntoView(options);
    //   }
    // }
  }
}
</script>

<style scoped>

</style>